import styled from 'styled-components';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

// sections
import { Banner, Form, Hero, TextCopy } from './containers';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

const Content = ({ hero, content, sidebar, banner = null, form = null }) => {
  return (
    <>
      <PageWrapper>
        <Navigation />
        <>
          <Hero images={hero} />
          <TextCopy content={content} sidebar={sidebar} />
          {banner && <Banner content={banner} />}
          {form && <Form content={form} />}
        </>
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Content;
export { Content };
