import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import { Container } from '@/components/Grid/Container';

export const BannerWrapper = styled.aside`
  display: block;
  width: 100%;
  padding: 40px 0px 44px;

  background: linear-gradient(
    91.13deg,
    #eff4f5 10.93%,
    #eff4f5 10.94%,
    #e7edef 94.82%
  );

  ${screen.md} {
    padding: 50px 0px;
  }

  ${screen.lg} {
    padding: 70px 0px;
  }

  ${screen.xxlg} {
    padding: 100px 0px;
  }
`;

export const BannerContainer = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;

  ${screen.md} {
    display: grid;
    grid-template-columns: 1fr 351px;
  }

  ${screen.lg} {
    grid-template-columns: 1fr 516px;
  }

  ${screen.xxlg} {
    grid-template-columns: 1fr 767px;
  }
`;

//

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${screen.md} {
    align-items: flex-start;
  }
`;

export const Title = styled.p`
  margin-bottom: 16px;

  color: #787b7d;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  &.full-title {
    color: #232323;
  }

  ${screen.md} {
    margin-bottom: 28px;

    line-height: 26px;
    text-align: start;
  }

  ${screen.lg} {
    margin-bottom: 36px;

    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    margin-bottom: 42px;

    font-size: 28px;
    line-height: 44px;
  }
`;

export const BulletList = styled.ul`
  margin: 0 0 16px;
  list-style: none;
  padding: 0;
  text-align: center;

  ${screen.md} {
    margin: 0 0 28px;

    text-align: start;
  }

  ${screen.lg} {
    margin: 0 0 36px;
  }

  ${screen.xxlg} {
    margin: 0 0 42px;
  }
`;

export const Bullet = styled.li`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    &::before {
      padding: 0px 10px;

      font-size: 20px;

      content: '•';
    }
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    text-align: start;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;

export const CTA = styled.a`
  color: #01cb2c;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-decoration: underline;

  ${screen.md} {
    font-size: 15px;
    line-height: 20px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 26px;
  }

  ${screen.xxlg} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const LogoWrapper = styled.div`
  display: block;
  width: 172px;
  margin-bottom: 16px;

  ${screen.md} {
    width: 175px;
    margin-bottom: 22px;
  }

  ${screen.lg} {
    width: 254px;
    margin-bottom: 40px;
  }

  ${screen.xxlg} {
    width: 472px;
    margin-bottom: 52px;
  }
`;

//

export const Picture = styled.picture`
  display: inline-grid;
  box-sizing: border-box;
  margin-bottom: 16px;
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  source {
    display: none;
  }

  ${screen.md} {
    margin-bottom: 0px;
  }
`;
