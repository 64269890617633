import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import { Container } from '@/components/Grid/Container';

export const FormWrapper = styled.aside`
  display: block;
  width: 100%;
  padding: 40px 0px 44px;

  background: linear-gradient(
    91.13deg,
    #eff4f5 10.93%,
    #eff4f5 10.94%,
    #e7edef 94.82%
  );

  ${screen.md} {
    padding: 50px 0px;
  }

  ${screen.lg} {
    padding: 70px 0px;
  }

  ${screen.xxlg} {
    padding: 100px 0px;
  }

  .full-title {
    color: #232323;
    max-width: 990px;
    margin: 0 auto;
  }
`;

export const FormContainer = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  padding: 20px 0 0;

  ${screen.md} {
    display: grid;
    grid-template-columns: 1fr 320px;
    padding: 40px 30px 0;
  }

  ${screen.lg} {
    grid-template-columns: 1fr 490px;
  }

  ${screen.xxlg} {
    padding: 60px 60px 0;
    grid-template-columns: 1fr 640px;
  }
`;

//

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${screen.md} {
    align-items: flex-start;
  }
`;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    text-align: start;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;

//

export const Picture = styled.picture`
  display: inline-grid;
  box-sizing: border-box;
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  source {
    display: none;
  }

  ${screen.md} {
    margin-bottom: 0px;
  }
`;
