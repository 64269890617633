/* eslint-disable @next/next/no-img-element */

// styles
import {
  BannerWrapper,
  BannerContainer,
  ContentWrapper,
  Title,
  BulletList,
  Bullet,
  CTA,
  LogoWrapper,
  Picture,
  Subtitle,
} from './Banner.styles';

// images
const sideImage = {
  small: '/images/content/rfpbanner/rfp-banner-mobile@2x.png',
  medium: '/images/content/rfpbanner/rfp-banner-medium@2x.png',
  large: '/images/content/rfpbanner/rfp-banner-lg@2x.png',
  xxlarge: '/images/content/rfpbanner/rfp-banner-xxlg@2x.png',
  alt: 'RFP Guide dashboard screeenshots',
};

export function Banner({ content }) {
  return (
    <BannerWrapper>
      <BannerContainer>
        <ContentWrapper>
          {content.logo && <LogoWrapper>{content.logo}</LogoWrapper>}
          <Title className={content.logo ? '' : 'full-title'}>
            {content.title}
          </Title>
          {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
          <BulletList>
            {content.bullets?.map((bullet, index) => {
              return <Bullet key={index}>{bullet}</Bullet>;
            })}
          </BulletList>
          {content.url && (
            <CTA href={content.url} target="_blank" rel="noopener noreferrer">
              {content.cta}
            </CTA>
          )}
        </ContentWrapper>
        <Picture>
          <source
            srcSet={
              content.image?.xxlarge ? content.image.xxlarge : sideImage.xxlarge
            }
            media="(min-width: 1920px)"
          />
          <source
            srcSet={
              content.image?.large ? content.image.large : sideImage.large
            }
            media="(min-width: 1024px)"
          />
          <source
            srcSet={
              content.image?.medium ? content.image.medium : sideImage.medium
            }
            media="(min-width: 768px)"
          />
          <img
            src={content.image?.small ? content.image.small : sideImage.small}
            alt={content.alt ? content.alt : sideImage.alt}
            width="767"
            height="520"
          />
        </Picture>
      </BannerContainer>
    </BannerWrapper>
  );
}
