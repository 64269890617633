import PropTypes from 'prop-types';

// components
import { Container } from '@/components/Grid/Container';

// styles
import {
  TextCopyWrapper,
  ContainerWrapper,
  Date,
  Title,
  ExcerptHighlight,
  Content,
  ContentBody,
  Sidebar,
  SidebarBox,
  SidebarTitle,
} from './TextCopy.styles';

export function TextCopy({ content, sidebar }) {
  return (
    <TextCopyWrapper>
      <Container>
        <ContainerWrapper>
          {content.date && <Date>{`Published ${content.date}`}</Date>}
          <Title>{content.title}</Title>
          <ExcerptHighlight>{content.highlight}</ExcerptHighlight>
          <Content>
            <ContentBody>{content.copy}</ContentBody>

            <Sidebar>
              <SidebarBox>
                <SidebarTitle>Continue reading:</SidebarTitle>
                <>
                  {sidebar.map((item, key) => {
                    var id = `sidelink-${key}`;
                    return (
                      <a
                        key={id}
                        href={item.url}
                        target={item.external ? `_blank` : ``}
                        rel={item.external ? `noreferrer noopener` : ``}
                      >
                        {item.label}
                      </a>
                    );
                  })}
                </>
              </SidebarBox>
            </Sidebar>
          </Content>
        </ContainerWrapper>
      </Container>
    </TextCopyWrapper>
  );
}

TextCopy.propTypes = {
  content: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    highlight: PropTypes.node,
    copy: PropTypes.node,
  }).isRequired,
  sidebar: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      external: PropTypes.bool,
    })
  ).isRequired,
};
