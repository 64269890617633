import React from 'react';
import PropTypes from 'prop-types';

// styles
import { HeroWrapper } from './Hero.styles';

export function Hero({ images }) {
    return <HeroWrapper bg={images} />;
}

Hero.propTypes = {
    images: PropTypes.shape({
        xxlarge: PropTypes.string,
        large: PropTypes.string,
        medium: PropTypes.string,
        small: PropTypes.string,
    }).isRequired,
};
