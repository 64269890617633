import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const HeroWrapper = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 410px;

  text-align: center;

  background-color: #029f58;
  background-image: url(/${(props) => props.bg.small});
  background-position: top center;
  background-size: cover;

  ${screen.md} {
    min-height: 328px;

    background-image: url(/${(props) => props.bg.medium});
  }

  ${screen.lg} {
    min-height: 487px;

    background-image: url(/${(props) => props.bg.large});
  }

  ${screen.xxlg} {
    min-height: 629px;

    background-image: url(/${(props) => props.bg.xxlarge});
  }
`;
