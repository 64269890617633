/* eslint-disable @next/next/no-img-element */
import { HubspotForm } from '@/containers/HubspotForm';

// styles
import {
  FormWrapper,
  FormContainer,
  ContentWrapper,
  Picture,
  Subtitle,
} from './Form.styles';
import { Container } from '@/components/Grid';
import { Title1 } from '@/components/Text';

export function Form({ content }) {
  return (
    <FormWrapper>
      <Container>
        <Title1 as="h2" className={'full-title'}>
          {content.title}
        </Title1>
      </Container>
      <FormContainer>
        <ContentWrapper>
          {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
        </ContentWrapper>
        {content.image && (
          <Picture>
            <source
              srcSet={content.image?.xxlarge ? content.image.xxlarge : ''}
              media="(min-width: 1920px)"
            />
            <source
              srcSet={content.image?.large ? content.image.large : ''}
              media="(min-width: 1024px)"
            />
            <source
              srcSet={content.image?.medium ? content.image.medium : ''}
              media="(min-width: 768px)"
            />
            <img
              src={content.image?.small ? content.image.small : ''}
              alt={content.alt ? content.alt : ''}
              width="767"
              height="520"
            />
          </Picture>
        )}
      </FormContainer>
      <Container>
        <HubspotForm
          {...content.form}
          disclaimer={true}
          theme="transparent"
        ></HubspotForm>
      </Container>
    </FormWrapper>
  );
}
