import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const TextCopyWrapper = styled.section`
  background: transparent;
`;

export const ContainerWrapper = styled.div`
  margin-top: -300px;
  padding: 32px 24px;

  text-align: center;

  background: #ffffff;

  ${screen.md} {
    margin-top: -180px;
    padding: 24px 20px;
  }
  ${screen.lg} {
    margin-top: -300px;
    padding: 36px;
  }
  ${screen.xxlg} {
    padding: 42px;
  }
`;

export const Title = styled.h2`
  margin: 8px 0;

  color: #01cb2c;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  text-align: center;

  ${screen.md} {
    font-size: 34px;
    line-height: 40px;
    text-align: left;
  }
  ${screen.lg} {
    margin: 12px 0;

    font-size: 48px;
    line-height: 54px;
  }

  ${screen.xxlg} {
    margin: 16px 0;

    font-size: 58px;
    line-height: 72px;
  }
`;

export const Date = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  text-align: center;

  ${screen.md} {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }
  ${screen.xxlg} {
    font-size: 26px;
    line-height: 38px;
  }
`;

export const ExcerptHighlight = styled.div`
  max-width: 92%;
  margin: 16px auto 0;

  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin: 16px 0 0;

    font-size: 16px;
    line-height: 26px;
    text-align: left;
  }

  ${screen.lg} {
    margin-top: 30px;

    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 44px;
  }
`;

export const Content = styled.div`
  position: relative;

  display: grid;

  grid-template-columns: auto;
  height: auto;
  padding: 24px 0;

  ${screen.md} {
    grid-template-columns: 1fr auto;
    gap: 24px;
  }
  ${screen.lg} {
    gap: 56px;
    padding: 40px 0;
  }
  ${screen.xxlg} {
    gap: 92px;
    padding: 98px 0;
  }
`;

export const ContentBody = styled.article`
  width: auto;

  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: left;

  h1,
  h2,
  h3,
  h4 {
    margin: 22px 0;

    color: #01cb2c;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    scroll-margin-top: 40px;

    ${screen.md} {
      margin: 36px 0;

      line-height: 26px;
    }

    ${screen.lg} {
      margin: 50px 0;

      font-size: 22px;
      line-height: 36px;
    }

    ${screen.xxlg} {
      margin: 64px 0;

      font-size: 30px;
      line-height: 42px;
    }
  }

  p {
    margin: 20px 0;

    ${screen.md} {
      margin: 22px 0;
    }
    ${screen.lg} {
      margin: 32px 0;
    }
    ${screen.xxlg} {
      margin: 42px 0;
    }
  }

  a {
    color: #242729;
    text-decoration-line: underline;
  }

  nav {
    margin: 24px 0;

    ${screen.md} {
      margin: 36px 0;
    }
    ${screen.lg} {
      margin: 52px 0;
    }
    ${screen.xxlg} {
      margin: 64px 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 18px 0;

        ${screen.lg} {
          margin: 24px 0;
        }

        a {
          color: #242729;
          text-decoration-line: underline;

          font-size: 14px;
          line-height: 22px;

          ${screen.md} {
            font-size: 15px;
            line-height: 20px;
          }

          ${screen.lg} {
            font-size: 20px;
            line-height: 26px;
          }

          ${screen.xxlg} {
            font-size: 26px;
            line-height: 38px;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 40px;
  }
`;

export const Sidebar = styled.aside`
  display: none;
  width: 100%;

  ${screen.md} {
    display: block;
    width: 191px;
  }
  ${screen.lg} {
    width: 262px;
  }
  ${screen.xxlg} {
    width: 365px;
  }
`;

export const SidebarBox = styled.nav`
  position: sticky;
  top: 20px;

  display: block;
  height: auto;
  padding: 20px;

  text-align: left;

  background: #f4f4f4;

  a {
    display: inline-block;
    margin: 12px 0;

    color: #929aa1;
    font-weight: 600;
    text-decoration-line: underline;
    font-size: 15px;
    line-height: 20px;

    &:hover {
      opacity: 0.8;
    }

    ${screen.lg} {
      margin: 14px 0;

      font-size: 20px;
      line-height: 26px;
    }

    ${screen.xxlg} {
      margin: 16px 0;

      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const SidebarTitle = styled.h3`
  color: #283241;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;
